var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-list" }, [
    _c("ul", [
      _c("li", [_vm._v("车辆自编号：" + _vm._s(_vm.item.vehicleId))]),
      _c("li", [_vm._v("车牌号：" + _vm._s(_vm.item.licensePlateNum))]),
      _c("li", [_vm._v("速度：" + _vm._s(_vm.speed))]),
      _c("li", [
        _vm._v("方向：" + _vm._s(_vm.locationHeading) + "° "),
        _c("img", { ref: "headingRef", attrs: { src: _vm.arrowUrl } }),
      ]),
      _c("li", [_vm._v("最近一次点位上报：" + _vm._s(_vm.item.time))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }