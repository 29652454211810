<template>
  <div class="info-list">
    <ul>
      <li>车辆自编号：{{ item.vehicleId }}</li>
      <li>车牌号：{{ item.licensePlateNum }}</li>
      <li>速度：{{ speed }}</li>
      <li>方向：{{ locationHeading }}° <img ref="headingRef" :src="arrowUrl" /></li>
      <li>最近一次点位上报：{{ item.time }}</li>
    </ul>
  </div>
</template>

<script>
import get from 'lodash/get'
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  mixins: [MonitorBase],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    lastUpdateTime() {
      return ''
    },
    onlineStatus() {
      return ''
    },
    speed() {
      const speed = this.item.runningState ? this.item.runningState.speed : 0
      if (speed) {
        return Number(speed).toFixed(2) + 'KM/H'
      } else {
        return '暂无速度'
      }
    },
    locationHeading() {
      const headingVal = Number(get(this.item, 'location.heading', 0))
      this.$nextTick(() => {
        // const el = document.getElementById('imgInfo')
        const el = this.$refs.headingRef
        if (el) {
          this.setArrow(el, headingVal)
        }
      })
      return headingVal ? headingVal.toFixed(2) : headingVal
    }
  }
}
</script>
<style lang="less" scoped>
.info-list {
  //   margin-left: 110px;
  margin-top: -5px;
  ul {
    margin-left: 0;
    padding-left: 0;
  }
}
.info-list ul li {
  height: 24px;
  line-height: 24px;
  color: #000;
}
</style>
